import { useState } from "react";
import axios from "axios";

const useCustomExportData = () => {
    const [loadingE, setLoadingE] = useState(false);
    const [urlE, setUrlE] = useState(null);
    const [errorE, setErrorE] = useState(null);

    const fetchExportDataUrl = async (data) => {
        setLoadingE(true);
        setErrorE(null);
        setUrlE(null);
        try {
            const response = await axios({
                method: "POST",
                url: process.env.REACT_APP_ExportDataCustomLambda, // 'http://localhost:5002/custom-export-data'
                headers: {
                  "x-api-key": process.env.REACT_APP_ExportDataLambdaAPIKey
                },
                data
            })
            if (response.data?.url) {
                setUrlE(response.data.url);
            } else {
                throw new Error("No URL found in response");
            }
        } catch (error) {
            setErrorE(error.response?.data?.message || error.message || "An error occurred");
            throw error;
        } finally {
            setLoadingE(false);
        }
    };

    return {
        loadingE,
        urlE,
        errorE,
        fetchExportDataUrl
    }
}

export default useCustomExportData