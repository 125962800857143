import { Link } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Spinner,
  Card,
  CardBody,
  Alert,
  Label,
  Form,
  Input,
  FormGroup,
} from "reactstrap";
import "./index.scss";
import useCustomExportData from "../../hooks/useCustomExportData";

const ExportData = ({ match, history }) => {
  const { loadingE, urlE, errorE, fetchExportDataUrl } = useCustomExportData();
  const { params } = match;
  const { type, id } = params;
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  // const [selectedSensors, setSelectedSensors] = useState([]);
  // const [selectedPlayers, setSelectedPlayers] = useState([]);
  // const [selectedTeams, setSelectedTeams] = useState([]);
  const [date_from, set_date_from] = useState(null);
  const [date_to, set_date_to] = useState(null);
  const [institution_id, set_institution_id] = useState("");
  const [team_id, set_team_id] = useState("");
  const [player_id, set_player_id] = useState("");
  const [sensor_id, set_sensor_id] = useState("");
  const [institutionsOptions, setInstitutionsOptions] = useState([]);
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [playersOptions, setPlayersOptions] = useState([]);
  const [sensorsOptions, setSensorsOptions] = useState([]);
  const [error, setError] = useState(null);
  const [allOptions, setAllOptions] = useState({});

  useEffect(() => {
    if (urlE) {
      window.open(urlE, "_blank");
    }
  }, [urlE]);

  const fetchOptions = useCallback(async (type, id) => {
    setIsOptionsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/export-data-option/${type}/${id}`);
      const allOpt = response.data.data;
      setAllOptions(allOpt);
      setInstitutionsOptions(allOpt.institutions || []);
      setTeamsOptions(allOpt.teams || []);
      setPlayersOptions(allOpt.users || []);
      setSensorsOptions(allOpt.sensors || []);
    } catch (issue) {
      console.error("Error fetching options:", issue);
      setError("Failed to fetch options. Please try again.");
    } finally {
      setIsOptionsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (type && id) {
      fetchOptions(type, id);
    }
  }, [type, id, fetchOptions]);

  const changeOptions = useCallback(() => {
    let filteredTeams = allOptions.teams || [];
    let filteredPlayers = allOptions.users || [];
    let filteredSensors = allOptions.sensors || [];

    if (institution_id) {
      filteredTeams = filteredTeams.filter(
        (team) => team.institution_id === institution_id
      );
    }
    let selectedTeams = [];
    const teamIds = filteredTeams.map((team) => team._id);
    selectedTeams = teamIds;
    if (team_id && !teamIds.includes(team_id)) {
      set_team_id("");
    } else if (team_id && teamIds.includes(team_id)) {
      selectedTeams = [team_id];
    }

    filteredPlayers = filteredPlayers.filter((player) =>
      player.teams.some((team) => selectedTeams.includes(team))
    );

    let selectedPlayer = [];
    const playerIds = filteredPlayers.map((player) => player._id);
    selectedPlayer = playerIds;
    if (player_id && !playerIds.includes(player_id)) {
      set_player_id("");
    } else if (player_id && playerIds.includes(player_id)) {
      selectedPlayer = [player_id];
    }

    if (Boolean(allOptions.users) && allOptions.users.length > 0) {

      filteredSensors = filteredSensors.filter((sensor) =>
        selectedPlayer.includes(sensor.user_id)
      );

      const sensorIds = filteredSensors.map((sensor) => sensor._id);
      if (sensor_id && !sensorIds.includes(sensor_id)) {
        set_sensor_id("");
      }
    }

    setTeamsOptions(filteredTeams);
    setPlayersOptions(filteredPlayers);
    setSensorsOptions(filteredSensors);
  }, [allOptions, institution_id, team_id, player_id, sensor_id]);

  useEffect(() => {
    changeOptions();
  }, [institution_id, team_id, player_id, changeOptions]);

  const getValidValue = (v) => (v != "" && v !== null ? v : null);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsPosting(true);
    const postObj = {
      team_id: getValidValue(team_id),
      institution_id: getValidValue(institution_id),
      player_id: getValidValue(player_id),
      sensor_id: getValidValue(sensor_id),
      date_to,
      date_from,
    };
    if (type === "org") postObj.organization_id = id;
    if (type === "team" && !postObj.team_id) postObj.team_id = id;
    if (type === "ins" && !postObj.institution_id) postObj.institution_id = id;
    if (type === "user" && !postObj.player_id) postObj.player_id = id;
    fetchExportDataUrl(postObj).finally(() => setIsPosting(false));;
  };

  const handleInsChange = (e) => {
    const value = e.target.value;
    set_institution_id(value);
    set_team_id("");
    set_player_id("");
    set_sensor_id("");
  };

  const handleTeamChange = (e) => {
    const value = e.target.value;
    set_team_id(value);
    set_player_id("");
    set_sensor_id("");
  };

  const handlePlayerChange = (e) => {
    const value = e.target.value;
    set_player_id(value);
    set_sensor_id("");
  };

  const handleSensorChange = (e) => {
    const value = e.target.value;
    set_sensor_id(value);
  };

  return (
    <div className="export-data-section">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="" onClick={() => history.goBack()}>
            {" "}
            Go Back{" "}
          </Link>{" "}
        </li>
      </ul>
      <div className="heading-bar">
        <h3>Export Data</h3>
      </div>
      <Card className="my-4">
        <CardBody>
          <Form onSubmit={handleFormSubmit}>
            {(type === "org" || type === "ins") && (
              <Row>
                {type === "org" && (
                  <Col>
                    <FormGroup>
                      <Label for="institution_id">Institution</Label>
                      <Input
                        id="institution_id"
                        name="institution_id"
                        value={institution_id}
                        type="select"
                        disabled={isPosting || isOptionsLoading || loadingE}
                        onChange={handleInsChange}
                      >
                        <option value="">Select Institution</option>
                        {institutionsOptions.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                {(type === "org" || type === "ins") && (
                  <Col>
                    <FormGroup>
                      <Label for="team_id">Team</Label>
                      <Input
                        id="team_id"
                        name="team_id"
                        type="select"
                        value={team_id}
                        disabled={isPosting || isOptionsLoading || loadingE}
                        onChange={handleTeamChange}
                      >
                        <option value="">Select Team</option>
                        {teamsOptions.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            )}
            <Row>
              {type !== "user" && (
                <Col>
                  <FormGroup>
                    <Label for="player_id">Player</Label>
                    <Input
                      id="player_id"
                      name="player_id"
                      type="select"
                      value={player_id}
                      disabled={isPosting || isOptionsLoading || loadingE}
                      onChange={handlePlayerChange}
                    >
                      <option value="">Select Player</option>
                      {playersOptions.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.full_name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              )}

              <Col>
                <FormGroup>
                  <Label for="sensor_id">Sensor</Label>
                  <Input
                    id="sensor_id"
                    name="sensor_id"
                    type="select"
                    value={sensor_id}
                    onChange={handleSensorChange}
                    disabled={isPosting || isOptionsLoading || loadingE}
                  >
                    <option value="">Select Sensor</option>
                    {sensorsOptions.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="date_from">From Date</Label>
                  <Input
                    id="date_from"
                    name="date_from"
                    type="date"
                    disabled={isPosting || loadingE}
                    onChange={(e) => set_date_from(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="date_to">To Date</Label>
                  <Input
                    id="date_to"
                    name="date_to"
                    type="date"
                    disabled={isPosting || loadingE}
                    onChange={(e) => set_date_to(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {(error || errorE) && (
              <Alert color="danger" className="my-3">
                {error ? error : errorE}
              </Alert>
            )}
            <Row>
              <Col className="text-center mt-3">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isPosting || isOptionsLoading || loadingE}
                  className="me-2"
                >
                  {isPosting ? <><Spinner size="sm" /> Exporting please wait .... </>: "Export Data"}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default ExportData;
